import { useState, FC, useEffect } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { CreateMeetingModal } from '../../Modals/CreateMeetingModal';
import { Loader } from '../../Loader/Loader';
import { useDeclineTaskMutation } from '../../../api/tenders';

import defineMeeting from '../../../assets/define-meeting.svg';
import plus from '../../../assets/Plus, Add.svg';
import calendar from '../../../assets/calendar-chedule.svg';
import clock from '../../../assets/clock.svg';
import pin from '../../../assets/pinIcon.svg';
import edit from '../../../assets/editAdminIcon.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import stopImage from '../../../assets/stopImage.svg';
import done from '../../../assets/Done, Check.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import { AllTasksCompleted } from '../AllTasksCompleted';
import moment from 'moment';

interface DefineMeetingTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTaskId: React.Dispatch<React.SetStateAction<string | number | null>>;
    setIsCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setDeadlineModal: React.Dispatch<React.SetStateAction<boolean>>;
    setApproveMeeting: React.Dispatch<React.SetStateAction<boolean>>;
    sendActivitiesFunc: any;
    checked: string;
    setChecked: React.Dispatch<React.SetStateAction<string>>;
    taskId: string | null | number;
    messageApi: any;
    isCreateModal: boolean;
    approveMeeting: boolean;
    isLoadingApproveTask: boolean;
    sendTaskFunc: () => void;
}

export const DefineMeetingTask: FC<DefineMeetingTaskProps> = ({
    data,
    role,
    setTaskId,
    setIsCreateModal,
    setTrigger,
    sendActivitiesFunc,
    checked,
    setChecked,
    taskId,
    messageApi,
    isCreateModal,
    sendTaskFunc,
    setApproveMeeting,
    isLoadingApproveTask,
    approveMeeting,
    setDeadlineModal
}) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.screen.width);
    function handleResize() {
        setScreenWidth(window.screen.width);
    }
    window.addEventListener('resize', handleResize);
    const [openCreateMeetingModal, setOpenCreateMeetintModal] = useState<boolean>(false);
    const [declineMeeting, setDeclineMeeting] = useState(false);

    const [declineTask, { isLoading: isLoadingDeclineTask }] = useDeclineTaskMutation();

    const declineTaskFunc = async () => {
        try {
            const formData = {
                ids: {
                    tenderId: data?.id,
                    taskId: taskId
                }
            };
            await declineTask(formData).unwrap();
            setDeclineMeeting(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    return (
        <>
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'define_phase_meeting') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.link && !elem.approved) {
                                return (
                                    <>
                                        <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                                            <div className="info-wrap__deadline">Task Deadline for School User</div>
                                            <div className="info-wrap__status">
                                                {data?.tasks_deadline_at
                                                    ? moment(data?.tasks_deadline_at).format('YYYY-MM-DD')
                                                    : 'Not indicated'}
                                            </div>
                                            <div className="info-wrap__edit" onClick={() => setDeadlineModal(true)}>
                                                <img src={edit} />
                                                Edit
                                            </div>
                                        </div>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task task ${
                                                elem.status !== 'pending_tr' ? 'green' : ''
                                            }`}
                                        >
                                            <div className="task__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            {role === 'tender_representative' && (
                                                <img
                                                    src={plus}
                                                    className="task__plus"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsCreateModal(true);
                                                        setOpenCreateMeetintModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'pending_tr' && elem.link && !elem.approved) {
                                return (
                                    <>
                                        <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                                            <div className="info-wrap__deadline">Task Deadline for School User</div>
                                            <div className="info-wrap__status">
                                                {data?.tasks_deadline_at
                                                    ? moment(data?.tasks_deadline_at).format('YYYY-MM-DD')
                                                    : 'Not indicated'}
                                            </div>
                                            <div className="info-wrap__edit" onClick={() => setDeadlineModal(true)}>
                                                <img src={edit} />
                                                Edit
                                            </div>
                                        </div>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete green
                            `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Date</div>
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ margin: '0 77px 0 0' }}
                                                    >
                                                        Time
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__name">Link to Questionnaire</div>
                                                    )}
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value" style={{ padding: '0' }}>
                                                        <img src={calendar} />
                                                        {elem.date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <img src={clock} />
                                                        {elem.time_from}- {elem.time_to}
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__value">
                                                            <img src={pin} />
                                                            <div className="task-complete__link">
                                                                <a href={elem?.link} target="_blanc">
                                                                    {elem.link}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {elem.comments?.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments?.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                {role !== 'super_admin' && (
                                                    <img
                                                        src={edit}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsCreateModal(false);
                                                            setOpenCreateMeetintModal(true);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'pending_su' && !elem.approved) {
                                return (
                                    <>
                                        <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                                            <div className="info-wrap__deadline">Task Deadline for School User</div>
                                            <div className="info-wrap__status">
                                                {data?.tasks_deadline_at
                                                    ? moment(data?.tasks_deadline_at).format('YYYY-MM-DD')
                                                    : 'Not indicated'}
                                            </div>
                                            <div className="info-wrap__edit" onClick={() => setDeadlineModal(true)}>
                                                <img src={edit} />
                                                Edit
                                            </div>
                                        </div>
                                        <div className={`tasks__task-complete task-complete grey`} key={elem.id}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    {' '}
                                                    <div className="task__pending" key={elem.id}>
                                                        <img src={pendingIcon} />
                                                        Pending
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Date</div>
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ margin: '0 77px 0 0' }}
                                                    >
                                                        Time
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__name">Link to Questionnaire</div>
                                                    )}
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value" style={{ padding: '0' }}>
                                                        <img src={calendar} />
                                                        {elem.date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <img src={clock} />
                                                        {elem.time_from}- {elem.time_to}
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__value">
                                                            <img src={pin} />
                                                            <div className="task-complete__link">
                                                                <a href={elem.link ? elem.link : ''} target="_blanc">
                                                                    {elem.link}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {elem.comments?.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments?.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'pending_tr' && elem.approved) {
                                return (
                                    <>
                                        <div className="tasks__info-wrap info-wrap" style={{ margin: '0 0 16px 0' }}>
                                            <div className="info-wrap__deadline">Task Deadline for School User</div>
                                            <div className="info-wrap__status">
                                                {data?.tasks_deadline_at
                                                    ? moment(data?.tasks_deadline_at).format('YYYY-MM-DD')
                                                    : 'Not indicated'}
                                            </div>
                                            <div className="info-wrap__edit" onClick={() => setDeadlineModal(true)}>
                                                <img src={edit} />
                                                Edit
                                            </div>
                                        </div>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete  ${
                                                checked ? 'blue' : 'grey'
                                            }`}
                                        >
                                            <div className="task-complete__title">
                                                <div>
                                                    <div className="task-complete__title-row">
                                                        <img src={defineMeeting} />
                                                        {elem?.name}
                                                    </div>
                                                    <div className="task-complete__title-row"></div>
                                                </div>
                                                <div>
                                                    {role !== 'super_admin' && (
                                                        <div className="tasks__checkbox-wrapper">
                                                            <div className="tasks__checkbox-container">
                                                                <div
                                                                    className={`tasks__checkbox ${
                                                                        checked === 'no_show' ? 'active' : ''
                                                                    }`}
                                                                    onClick={() => setChecked('no_show')}
                                                                ></div>
                                                                <div>No Show</div>
                                                            </div>
                                                            <div
                                                                className="tasks__checkbox-container"
                                                                onClick={() => setChecked('took_place')}
                                                            >
                                                                <div
                                                                    className={`tasks__checkbox ${
                                                                        checked === 'took_place' ? 'active' : ''
                                                                    }`}
                                                                ></div>
                                                                <div>Took Place</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Date</div>
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ margin: '0 77px 0 0' }}
                                                    >
                                                        Time
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__name">Link to Questionnaire</div>
                                                    )}
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value" style={{ padding: '0' }}>
                                                        <img src={calendar} />
                                                        {elem.date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <img src={clock} />
                                                        {elem.time_from}- {elem.time_to}
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__value">
                                                            <img src={pin} />
                                                            <div className="task-complete__link">
                                                                <a href={elem.link ? elem.link : ''} target="_blanc">
                                                                    {elem.link}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {elem.comments?.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__name">My Comment</div>
                                                        </div>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                {elem.comments?.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su' && !elem.approved) {
                                return (
                                    <div className={`tasks__task-complete task-complete`} key={elem.id}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={defineMeeting} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className="task-complete__decline"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setDeclineMeeting(true);
                                                    }}
                                                >
                                                    Reject
                                                </div>
                                                <Tooltip
                                                    title="To approve this meeting, you need to complete Questionnaire first"
                                                    overlayClassName={` ${
                                                        !elem.link_clicked
                                                            ? 'custom-tooltip-disabled'
                                                            : 'custom-tooltip'
                                                    }`}
                                                >
                                                    <div
                                                        className={`task-complete__approved ${
                                                            !elem.link_clicked ? 'disabled-blue-button' : ''
                                                        }`}
                                                        onClick={() => {
                                                            if (elem.link_clicked) {
                                                                setApproveMeeting(true);
                                                            }
                                                        }}
                                                    >
                                                        Approve
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Date</div>
                                                <div className="task-complete__name" style={{ margin: '0 77px 0 0' }}>
                                                    Time
                                                </div>
                                                {screenWidth > 1024 && (
                                                    <div className="task-complete__name">Link to Questionnaire</div>
                                                )}
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value" style={{ padding: '0' }}>
                                                    <img src={calendar} />
                                                    {elem.date}
                                                </div>
                                                <div className="task-complete__value">
                                                    <img src={clock} />
                                                    {elem.time_from}- {elem.time_to}
                                                </div>
                                                {screenWidth > 1024 && (
                                                    <div className="task-complete__value">
                                                        <img src={pin} />
                                                        <div className="task-complete__link">
                                                            <a
                                                                href={elem.link ? elem.link : ''}
                                                                target="_blanc"
                                                                onClick={sendActivitiesFunc}
                                                            >
                                                                {elem.link}
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            {elem.comments?.tender_representative && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments?.tender_representative}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_tr' && elem.approved) {
                                return (
                                    <>
                                        <AllTasksCompleted />
                                        <div className={`tasks__task-complete task-complete green`} key={elem.id}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div
                                                        className="task-complete__decline"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setDeclineMeeting(true);
                                                        }}
                                                    >
                                                        Reject
                                                    </div>
                                                    <div className="task-complete__approved-active">
                                                        <img src={done} />
                                                        Approved
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Date</div>
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ margin: '0 77px 0 0' }}
                                                    >
                                                        Time
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__name">Link to Questionnaire</div>
                                                    )}
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value" style={{ padding: '0' }}>
                                                        <img src={calendar} />
                                                        {elem.date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <img src={clock} />
                                                        {elem.time_from}- {elem.time_to}
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__value">
                                                            <img src={pin} />
                                                            <div className="task-complete__link">
                                                                <a href={elem.link ? elem.link : ''} target="_blanc">
                                                                    {elem.link}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {elem.comments?.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value school-user">
                                                                <img src={messageGrey} />
                                                                {elem.comments?.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'declined' && !elem.approved) {
                                return (
                                    <>
                                        {' '}
                                        <AllTasksCompleted />
                                        <div className={`tasks__task-complete task-complete red`} key={elem.id}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div
                                                        className="task-complete__decline-active"
                                                        onClick={() => setDeclineMeeting(true)}
                                                    >
                                                        <img src={stopImage} />
                                                        Rejected
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Date</div>
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ margin: '0 77px 0 0' }}
                                                    >
                                                        Time
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__name">Link to Questionnaire</div>
                                                    )}
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value" style={{ padding: '0' }}>
                                                        <img src={calendar} />
                                                        {elem.date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <img src={clock} />
                                                        {elem.time_from}- {elem.time_to}
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__value">
                                                            <img src={pin} />
                                                            <div className="task-complete__link">
                                                                <span>{elem.link}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {elem.comments?.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value school-user">
                                                                <img src={messageGrey} />
                                                                {elem.comments?.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'completed' && elem.approved) {
                                return (
                                    <>
                                        {' '}
                                        <AllTasksCompleted />
                                        <div className={`tasks__task-complete task-complete green`} key={elem.id}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={defineMeeting} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div className="task-complete__decline disable">Reject</div>
                                                    <div className="task-complete__approved-active">
                                                        <img src={done} />
                                                        Approved
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Date</div>
                                                    <div
                                                        className="task-complete__name"
                                                        style={{ margin: '0 77px 0 0' }}
                                                    >
                                                        Time
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__name">Link to Questionnaire</div>
                                                    )}
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value" style={{ padding: '0' }}>
                                                        <img src={calendar} />
                                                        {elem.date}
                                                    </div>
                                                    <div className="task-complete__value">
                                                        <img src={clock} />
                                                        {elem.time_from}- {elem.time_to}
                                                    </div>
                                                    {screenWidth > 1024 && (
                                                        <div className="task-complete__value">
                                                            <img src={pin} />
                                                            <div className="task-complete__link">
                                                                <a href={elem.link ? elem.link : ''} target="_blanc">
                                                                    {elem.link}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                {elem.comments?.tender_representative && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value school-user">
                                                                <img src={messageGrey} />
                                                                {elem.comments?.tender_representative}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        }
                    }
                })}

            {openCreateMeetingModal && (
                <CreateMeetingModal
                    openModal={openCreateMeetingModal}
                    setOpenModal={setOpenCreateMeetintModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    isCreateModal={isCreateModal}
                />
            )}
            {approveMeeting && (
                <Modal
                    className="confirmModal"
                    centered
                    open={approveMeeting}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setApproveMeeting(false)} block className="cancel">
                                Cancel
                            </Button>
                            <Button type="primary" style={{ width: '100%' }} onClick={sendTaskFunc}>
                                {isLoadingApproveTask ? <Loader /> : 'Confirm'}
                            </Button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to Confirm the Define Phase Meeting on{' '}
                            <span>{data?.phase_tasks[0]?.date}</span> from{' '}
                            <span>{data?.phase_tasks[0]?.time_from}</span> to{' '}
                            <span>{data?.phase_tasks[0]?.time_to}</span>?
                        </div>
                    </div>
                </Modal>
            )}
            {declineMeeting && (
                <Modal
                    className="confirmModal"
                    centered
                    open={declineMeeting}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setDeclineMeeting(false)} block className="cancel">
                                Cancel
                            </Button>
                            <div style={{ width: '100%' }} onClick={declineTaskFunc} className="decline-btn">
                                {isLoadingDeclineTask ? <Loader /> : 'Reject'}
                            </div>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to Reject Meeting on <span>{data?.phase_tasks[0]?.date}</span> from{' '}
                            <span>{data?.phase_tasks[0]?.time_from}</span> to{' '}
                            <span>{data?.phase_tasks[0]?.time_to}</span>?
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
