import { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { Loader } from '../../Loader/Loader';
import { downloadFileFunc } from '../../../api/axiosCruds';
import { useApproveTaskMutation, useDeleteFileMutation, useDeleteSupportDocMutation } from '../../../api/tenders';
import { SupportingModal } from '../../Modals/SupportingModal';
import { UploadFileModal } from '../../Modals/UploadFileModal';
import { DeclineModal } from '../../Modals/DeclineModal';

import deleteIcon from '../../../assets/DeleteIcon.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import SuppDocIcon from '../../../assets/SuppDocIcon.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import done from '../../../assets/Done, Check.svg';
import stopImage from '../../../assets/stopImage.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';
import file from '../../../assets/Documents, File, Upload.svg';
import { AllTasksCompleted } from '../AllTasksCompleted';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTaskId: React.Dispatch<React.SetStateAction<string | number | null>>;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    setSupportingModal: React.Dispatch<React.SetStateAction<boolean>>;
    taskId: string | null | number;
    messageApi: any;
    isCreateModal: boolean;
    supportingModal: boolean;
}

export const DefineSupportingDocumentTask: FC<DefineSupportingDocumentTaskProps> = ({
    data,
    role,
    taskId,
    setTaskId,
    messageApi,
    setTrigger,
    isCreateModal,
    supportingModal,
    setSupportingModal
}) => {
    const [isDeleteSupDocModal, setIsDeleteSupDocModal] = useState<boolean>(false);
    const [isUploadModal, setIsUploadModal] = useState<boolean>(false);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [isDeclineModal, setIsDeclineModal] = useState<boolean>(false);

    const [deleteFile, { isLoading: isLoadingDeleteFile }] = useDeleteFileMutation();

    const [approveTask] = useApproveTaskMutation();

    const downloadFunc = (fileId: string, taskId: number) => {
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];
                downloadPDF(response, name);
            })
            .catch(() => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        if (filename.includes('pdf')) {
            let headerLine = response.headers['content-disposition'];

            if (headerLine) {
                let startFileNameIndex = headerLine.indexOf('"') + 1;
                let endFileNameIndex = headerLine.lastIndexOf('"');
                filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            }
        }

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    const deleteFileFunc = async () => {
        if (role === 'school_user') {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file: data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.school_user[0].uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        } else {
            try {
                const formData = {
                    tenderId: data.id,
                    taskId: taskId,
                    file:
                        data?.phase_tasks?.find((elem: any) => elem.id === taskId)?.files.tender_representative[0]
                            .uuid || null
                };
                await deleteFile(formData).unwrap();
                await setIsDeleteFileModal(false);
                await setTrigger((prev: boolean) => !prev);
            } catch {}
        }
    };

    const [
        deleteSupportDoc,
        {
            data: deletedSupportDoc,
            isLoading: isLoadingDeleteSupportDoc,
            isSuccess: isSuccessDeleteSupportDoc,
            isError: isErrorDeleteSupportDoc
        }
    ] = useDeleteSupportDocMutation();

    useEffect(() => {
        if (isSuccessDeleteSupportDoc) {
            messageApi.success(deletedSupportDoc.message);
        }
        if (isErrorDeleteSupportDoc) {
            messageApi?.error('Something went wrong, please try again');
        }
    }, [isSuccessDeleteSupportDoc, isErrorDeleteSupportDoc]);

    const deleteSupportDocFunc = async () => {
        try {
            const formData = {
                tenderId: data.id,
                taskId: taskId
            };
            await deleteSupportDoc(formData).unwrap();
            await setIsDeleteSupDocModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const approveTaskFunc = async (taskId: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId
            };
            await approveTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    return (
        <>
            {data?.phase_tasks.length &&
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'define_supporting_document') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem?.approved) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Document Name</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">{elem.document_name}</div>
                                            </div>
                                            {elem.comments.tender_representative && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">My Comment</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.comments.tender_representative}
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {role !== 'super_admin' && (
                                                <img
                                                    src={deleteIcon}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsDeleteSupDocModal(true);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su' && !elem?.approved) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete 
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Document Name</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">{elem.document_name}</div>
                                            </div>
                                            {elem.comments.tender_representative && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">My Comment</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.comments.tender_representative}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (elem.status === 'need_approve_tr' && !elem?.approved && !elem.declined) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete 
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            {role !== 'super_admin' && (
                                                <div className="task-complete__title-row">
                                                    <div
                                                        className={`task-complete__decline ${
                                                            elem.file_downloaded ? '' : 'disable'
                                                        }`}
                                                        onClick={() => {
                                                            if (elem.file_downloaded) {
                                                                setTaskId(elem.id);
                                                                setIsDeclineModal(true);
                                                            }
                                                        }}
                                                    >
                                                        Reject
                                                    </div>

                                                    <div
                                                        className={`task-complete__approved ${
                                                            !elem.file_downloaded ? 'disabled-blue-button' : ''
                                                        }`}
                                                        onClick={() => {
                                                            if (elem.file_downloaded) {
                                                                approveTaskFunc(elem.id);
                                                            }
                                                        }}
                                                    >
                                                        Approve
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="task-complete__info">
                                            <div className="task-complete__wrap column">
                                                <div className="task-complete__name">Received File</div>
                                                <div className="task-complete__value file">
                                                    <img src={ReceivedFile} className="upload-download-icon" />
                                                    {elem.files.school_user[0]?.name}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div
                                                    className={`task-complete__download-btn `}
                                                    onClick={() => {
                                                        downloadFunc(elem.files.school_user[0]?.uuid, elem.id);
                                                    }}
                                                >
                                                    <img src={downloadIcon} className="upload-download-icon" />
                                                    Download
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_approve_tr' && elem?.approved) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {role !== 'super_admin' && (
                                                    <div
                                                        className={`task-complete__decline ${
                                                            elem.file_downloaded ? '' : 'disable'
                                                        }`}
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsDeclineModal(true);
                                                        }}
                                                    >
                                                        Reject
                                                    </div>
                                                )}

                                                <div className="task-complete__approved-active">
                                                    <img src={done} />
                                                    Approved
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info">
                                            <div className="task-complete__wrap column">
                                                <div className="task-complete__name">Received File</div>
                                                <div className="task-complete__value file">
                                                    <img src={ReceivedFile} className="upload-download-icon" />

                                                    {elem.files.school_user[0]?.name}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div
                                                    className={`task-complete__download-btn `}
                                                    onClick={() => {
                                                        downloadFunc(elem.files.school_user[0]?.uuid, elem.id);
                                                    }}
                                                >
                                                    <img src={downloadIcon} className="upload-download-icon" />
                                                    Download
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_approve_tr' && elem.declined) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete red
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className={`task-complete__approved ${
                                                        !elem.file_downloaded ? 'disabled-blue-button' : ''
                                                    }`}
                                                    onClick={() => {
                                                        if (elem.file_downloaded) {
                                                            approveTaskFunc(elem.id);
                                                        }
                                                    }}
                                                >
                                                    Approve
                                                </div>

                                                <div
                                                    className="task-complete__decline-active"
                                                    style={{ margin: '0 0 0 16px' }}
                                                >
                                                    <img src={stopImage} />
                                                    Rejected
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info">
                                            <div className="task-complete__wrap column">
                                                <div className="task-complete__name">Received File</div>
                                                <div className="task-complete__value file">
                                                    <img src={ReceivedFile} className="upload-download-icon" />

                                                    {elem.files.school_user[0]?.name}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div
                                                    className={`task-complete__download-btn `}
                                                    onClick={() => {
                                                        downloadFunc(elem.files.school_user[0]?.uuid, elem.id);
                                                    }}
                                                >
                                                    <img src={downloadIcon} className="upload-download-icon" />
                                                    Download
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {elem.comments?.declined && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">My Comment</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        {elem.comments?.declined}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_su') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info">
                                            <div className="task-complete__wrap column">
                                                <div className="task-complete__name">Received File</div>
                                                <div className="task-complete__value file">
                                                    <img src={ReceivedFile} className="upload-download-icon" />

                                                    {elem.files.school_user[0]?.name}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div
                                                    className={`task-complete__download-btn `}
                                                    onClick={() => {
                                                        downloadFunc(elem.files.school_user[0]?.uuid, elem.id);
                                                    }}
                                                >
                                                    <img src={downloadIcon} className="upload-download-icon" />
                                                    Download
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {elem.comments?.declined && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">My Comment</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        {elem.comments?.declined}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'completed' && elem?.approved) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className={`task-complete__decline ${
                                                        elem.file_downloaded ? '' : 'disable'
                                                    }`}
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsDeclineModal(true);
                                                    }}
                                                >
                                                    Reject
                                                </div>
                                                <div className="task-complete__approved-active">
                                                    <img src={done} />
                                                    Approved
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info">
                                            <div className="task-complete__wrap column">
                                                <div className="task-complete__name">Received File</div>
                                                <div className="task-complete__value file">
                                                    <img src={ReceivedFile} className="upload-download-icon" />

                                                    {elem.files.school_user[0]?.name}
                                                </div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div
                                                    className={`task-complete__download-btn `}
                                                    onClick={() => {
                                                        downloadFunc(elem.files.school_user[0]?.uuid, elem.id);
                                                    }}
                                                >
                                                    <img src={downloadIcon} className="upload-download-icon" />
                                                    Download
                                                </div>
                                            </div>
                                        </div>

                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (
                                (elem.status === 'pending_su' && !elem?.approved && !elem.files.school_user[0]?.uuid) ||
                                (elem.status === 'need_resend_su' && !elem.declined)
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                <div
                                                    className={`task-complete__download-btn $`}
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsUploadModal(true);
                                                    }}
                                                >
                                                    <img src={file} className="upload-download-icon" />
                                                    Upload File
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Document Name</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">{elem.document_name}</div>
                                            </div>
                                            {elem.comments.tender_representative && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.tender_representative}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                (elem.status === 'pending_su' && !elem?.approved && elem.files.school_user[0]?.uuid) ||
                                elem.status === 'resend_su'
                            ) {
                                return (
                                    <>
                                        <div
                                            key={elem.id}
                                            className={`tasks__task-complete task-complete green
                                    `}
                                        >
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={SuppDocIcon} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>

                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Document Name</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">{elem?.document_name}</div>
                                            </div>
                                            {elem?.comments?.tender_representative && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem?.comments?.tender_representative}
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {elem?.files?.school_user?.[0]?.name && (
                                                <div className="task-complete__info-wrap">
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Uploaded File</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value file">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem?.files?.school_user?.[0]?.name}
                                                        </div>
                                                    </div>

                                                    <>
                                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                                            <>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__name">
                                                                        My Comment
                                                                    </div>
                                                                </div>
                                                                <div className="task-complete__wrap">
                                                                    <div className="task-complete__value">
                                                                        {
                                                                            elem.comments.files[
                                                                                elem.files.school_user[0]?.uuid
                                                                            ]
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                    <img
                                                        src={deleteIcon}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsDeleteFileModal(true);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'need_approve_tr') {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                <div className="task__pending" key={elem.id}>
                                                    <img src={pendingIcon} />
                                                    Pending
                                                </div>
                                            </div>
                                        </div>

                                        <div className="task-complete__wrap">
                                            <div className="task-complete__name">Document Name</div>
                                        </div>
                                        <div className="task-complete__wrap">
                                            <div className="task-complete__value">{elem.document_name}</div>
                                        </div>
                                        {elem.comments.tender_representative && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.tender_representative}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {elem.comments.files[elem.files.school_user[0]?.uuid] && (
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__info">
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">Uploaded File</div>
                                                        <div className="task-complete__value file">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.school_user[0]?.name}
                                                        </div>
                                                    </div>
                                                </div>

                                                <>
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">My Comment</div>
                                                        <div className="task-complete__value">
                                                            {elem.comments.files[elem.files.school_user[0]?.uuid]}
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_su' && elem.declined) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                                    `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SuppDocIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {' '}
                                                <div
                                                    className={`task-complete__download-btn $`}
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsUploadModal(true);
                                                    }}
                                                >
                                                    <img src={file} className="upload-download-icon" />
                                                    Upload File
                                                </div>
                                            </div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Document Name</div>
                                            </div>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value">{elem.document_name}</div>
                                            </div>
                                            {elem.comments.declined && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.declined}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        }
                    }
                })}
            {isDeleteSupDocModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteSupDocModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteSupDocModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteSupportDocFunc}>
                                {isLoadingDeleteSupportDoc ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to remove the Supporting document:{' '}
                            {data.phase_tasks.find((elem) => elem.id === taskId)?.document_name}?
                        </div>
                    </div>
                </Modal>
            )}
            {supportingModal && (
                <SupportingModal
                    openModal={supportingModal}
                    setOpenModal={setSupportingModal}
                    data={data}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                />
            )}
            {isUploadModal && (
                <UploadFileModal
                    openModal={isUploadModal}
                    setOpenModal={setIsUploadModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    title="Supporting Document"
                    subtitle=""
                />
            )}
            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFileFunc}>
                                {isLoadingDeleteFile ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
            {isDeclineModal && (
                <DeclineModal
                    openModal={isDeclineModal}
                    setOpenModal={setIsDeclineModal}
                    data={data}
                    setTrigger={setTrigger}
                    taskId={taskId}
                    title="Reject Supporting Document"
                />
            )}
        </>
    );
};
